import styled from "styled-components";
import { Colors, Rem, BP } from "../../../commons/Theme";
import { motion } from "framer-motion";

const getTextColor = (isDarkBackground) =>
  isDarkBackground ? Colors.white : Colors.black;

export const StyledPreTech = styled(motion.h2)`
  color: ${({ background }) => getTextColor(background)};
  font-size: ${Rem(24)};
  padding: ${Rem(70)} ${Rem(20)};
  letter-spacing: 3px;
  @media (${BP.tablet}) {
    text-align: center;
    padding: ${Rem(120)} ${Rem(270)};
  }
`;
