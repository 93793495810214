import { Colors } from "../../commons/Theme";

export const fadeIn = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.5
    }
  }
};

export const fadeOnTop = {
  offscreen: {
    y: -50,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1
    }
  }
};

export const fadeLinkOnBottom = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    }
  }
};

export const fadeOnBottom = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    },
  },
};

export const fadeOnBottomSlider = {
  offscreen: {
    y: 50,
    opacity: 1,
    color: Colors.black,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    color: Colors.white,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    },
  },
};

// export const fadeOnBottom = {
//   offscreen: {
//     y: 50,
//     opacity: 0,
//   },
//   onscreen: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       type: "spring",
//       bounce: 0.2,
//       duration: 1,
//       delay: 1,
//     },
//   },
// };

export const fadeFromLeft = {
  offscreen: {
    x: -50,
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
      delay: 0.5,
    },
  },
};

export const fadeFromLeftBee = {
  offscreen: {
    x: -50,
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 6,
    },
  },
};

export const heroBottom = {
  offscreen: {
    y: 50,
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    }
  }
};

export const staggerChildren = {
  offscreen: {
    opacity: 1
  },
  onscreen: {
    opacity: 1,
    transition: {
      delay: 0.3,
      staggerChildren: 0.08
    }
  }
};

export const staggerChildrenBee = {
  offscreen: {
    opacity: 1
  },
  onscreen: {
    opacity: 1,
    transition: {
      delay: 1,
      staggerChildren: 1
    }
  }
};

// export const staggerChildrenSlider = {
//   offscreen: {
//     opacity: 1,
//     backgroundColor: Colors.white
//   },
//   onscreen: {
//     opacity: 1,
//     backgroundColor: Colors.black,
//     transition: {
//       delay: 0.3,
//       staggerChildren: 0.08
//     }
//   }
// };

export const fadeGridImgFromLeft = {
  offscreen: {
    x: -50,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    }
  }
};

export const fadeGridImgFromRight = {
  offscreen: {
    x: 50,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
    }
  }
};

export const fadeLineFromLeft = {
  offscreen: {
    x: 20,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
      delay: 1
    }
  }
};

export const fadeLineFromRight = {
  offscreen: {
    x: -20,
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1,
      delay: 3
    }
  }
};
