import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer"; 
import {
  StyledPreTech
} from "./style";
import {fadeOnBottom } from "../animations";

export default (props) => {
  const {
    preTechContent,
    background,
    setBackground,
    isWrapperActive
  } = props
  const [ref, inView] = useInView({ threshold: 0.3 });
  
  useEffect(() => {
    if (inView ) {
      setBackground(true)
    } else if (!isWrapperActive) {
      setBackground(false)
    }
  }, [inView]);

  return (
    <StyledPreTech ref={ref} dangerouslySetInnerHTML={{ __html: preTechContent }} background={background} variants={fadeOnBottom} />
  );
};
